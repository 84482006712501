<template>
  <v-app>
    <div class="card">
      <div class="card-header">
        <div class="card-title pt-3 px-3 d-flex justify-content-between">
          <div class="breadcrumb-left">
            <h4> Quiz result</h4>
            <div class="breadcrumb-sub-header">
                <router-link to="/dashboard">Dashboard</router-link>
                \ Quiz result
            </div>
          </div>
        </div>
      </div>
      <div class="card-body ">
        <div class="row">
          <div class="col-12">
            <table class="table">
              <thead>
                <td class="wrap-column px-3">Candidate Name</td>
                <td class="wrap-column px-3">Marks Obtained</td>
                <td class="wrap-column px-3">Remarks</td>
              </thead>
              <tbody>
                <tr v-for="(result, index) of quiz_result" v-if="quiz_result.length > 0" :key="index">
                  <td class="px-3 wrap-column">{{ result.user_full_name }}</td>
                  <td class="px-3 wrap-column">{{ result.marks_obtained }}</td>
                  <td class="px-3 wrap-column">
                    <span
                      class="font-weight-bold"
                      :class="
                        result.remarks == 'FAIL' ? 'text-danger' : 'text-success'
                      "
                      >{{ result.remarks }}</span
                    >
                  </td>
                </tr>
                <tr v-if="quiz_result.length == 0">
                  <td class="text-center px-3 bg-white" colspan="3">Data not available</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
      
  </v-app>
</template>

<script>
import { QuizMixin } from "@/mixins/QuizMixin";

export default {
  name: "Index",
  mixins: [QuizMixin],
  data() {
    return {};
  },
  computed: {
    quiz_id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.getQuizResult(this.quiz_id);
  },
};
</script>
